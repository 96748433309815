var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-text-field',{attrs:{"dense":"","rounded":"","outlined":"","single-line":"","hide-details":"","prepend-inner-icon":"$search","label":_vm.$t('planning.filterAssets')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"transparent mt-2",attrs:{"dense":"","item-key":"_id","search":_vm.search,"items":_vm.assets,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","loading":_vm.loading,"custom-filter":_vm.filterAssets},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('planning.fetchingAsset'))+" ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('planning.noAsset'))+" ")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('planning.noAssetResult', { search: _vm.search }))+" ")]},proxy:true},{key:"item.machinename",fn:function({ item }){return [_c('div',[_c('strong',[_vm._v(_vm._s(item.machinename))])]),_c('div',[_vm._v(_vm._s(item.machinecode))])]}},{key:"item.manualplanstart",fn:function({ item }){return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"input-value":!item.manualplanstart,"hide-details":""},on:{"change":function($event){return _vm.updateAssetConfig({
          id: item._id,
          payload: { manualplanstart: !item.manualplanstart }
        })}}})]}},{key:"item.manualplanstop",fn:function({ item }){return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"input-value":!item.manualplanstop,"hide-details":""},on:{"change":function($event){return _vm.updateAssetConfig({
          id: item._id,
          payload: { manualplanstop: !item.manualplanstop }
        })}}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }