<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    transition="dialog-transition"
    width="95%"
  >
    <template #activator="{ on }">
      <v-btn
        v-on="on"
        small
        color="secondary"
        class="text-none mr-2"
      >
        <v-icon left small v-text="'$maintenance'"></v-icon>
        {{ $t('planning.equipmentChange') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="title font-weight-regular justify-space-between">
        <v-row>
          <v-col cols="4">
            {{ $t('planning.equipmentChange') }}
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          class="text-none mr-2"
          icon
          :disabled="!this.mouldChange.length"
          @click="onBtExport">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <ag-grid-vue
          :rowData="mouldChange"
          :columnDefs="columnDefs"
          :gridOptions="gridOptions"
          :localeText="agGridLocaleText"
          :class="`${agGridTheme} mt-2`"
          :defaultColDef="defaultColDef"
          style="width: 100%; height: 300px;"
        ></ag-grid-vue>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import { AgGridVue } from 'ag-grid-vue';

export default {
  name: 'EquipmentChange',
  components: {
    AgGridVue,
  },
  data() {
    return {
      dialog: false,
      gridApi: null,
      gridOptions: null,
      gridColumnApi: null,
      defaultColDef: null,
    };
  },
  created() {
    this.gridOptions = {};
    this.defaultColDef = {
      filter: true,
      editable: false,
      resizable: true,
      checkboxSelection: this.isFirstColumn,
      headerCheckboxSelection: this.isFirstColumn,
      headerCheckboxSelectionFilteredOnly: this.isFirstColumn,
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  beforeMount() {
    this.columnDefs = [
      {
        field: 'planid',
        headerName: this.$t('planning.plan'),
        colId: '1',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'machinename',
        headerName: this.$t('planning.machine'),
        colId: '2',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'moldname',
        headerName: this.$t('planning.equipment'),
        colId: '3',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'partname',
        headerName: this.$t('planning.part'),
        colId: '4',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'changetime',
        headerName: this.$t('planning.changeTime'),
        colId: '5',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'plannedquantity',
        headerName: this.$t('planning.plannedQty'),
        colId: '6',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'status',
        headerName: this.$t('planning.status'),
        colId: '7',
        filter: 'agSetColumnFilter',
      },
    ];
  },
  computed: {
    ...mapGetters('productionPlanning', ['machinewisePlans']),
    ...mapGetters('helper', ['agGridLocaleText', 'agGridTheme']),
    mouldChange() {
      const mouldChangeData = [];
      if (this.machinewisePlans !== null) {
        let mouldName = null;
        let machine = null;
        Object.entries(this.machinewisePlans).forEach(([key, value]) => {
          if (machine === null || machine !== key) {
            machine = key;
            mouldName = null;
          }
          value.forEach((data) => {
            if (mouldName === null) {
              mouldName = data.moldname;
            }
            if (mouldName !== data.moldname) {
              const mouldData = {
                machinename: data.machinename,
                moldname: data.moldname,
                planid: data.planid,
                partname: data.partname,
                plannedquantity: data.plannedquantity,
                status: this.$t(`planning.${data.status}`),
                changetime: this.dateFormatter(data.scheduledstart),
              };
              if (data.actualstart) {
                mouldData.changetime = this.dateFormatter(data.actualstart);
              }
              mouldChangeData.push(mouldData);
              mouldName = data.moldname;
            }
          });
        });
      }
      return mouldChangeData;
    },
  },
  methods: {
    dateFormatter(params) {
      const date = new Date(params).getDate();
      const month = new Date(params).getMonth();
      const year = new Date(params).getFullYear();
      const hr = new Date(params).getHours();
      const min = new Date(params).getMinutes();
      return `${date.toString().padStart(2, 0)}-${(month + 1).toString().padStart(2, 0)}-${year} ${hr.toString().padStart(2, 0)}:${min.toString().padStart(2, 0)}`;
    },
    onBtExport() {
      const params = {
        fileName: `Equipment Change - ${new Date().toLocaleString()}`,
      };
      this.gridOptions.api.exportDataAsExcel(params);
    },
  },

};
</script>
