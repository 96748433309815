var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('star-plan',{attrs:{"listType":_vm.listType,"starred":_vm.starred,"planId":_vm.planId}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.inProgress || _vm.notStarted)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
          name: 'editProductionPlan',
          params: { id: _vm.planId },
        })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e()]}}])},[_vm._v(" "+_vm._s(_vm.$t('planning.editPlan'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
          name: 'duplicateProductionPlan',
          params: { id: _vm.planId },
        })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('planning.duplicatePlan'))+" ")]),(_vm.inProgress)?_c('abort-plan',{attrs:{"listType":_vm.listType,"planId":_vm.planId}}):(_vm.notStarted)?_c('delete-plan',{attrs:{"listType":_vm.listType,"planId":_vm.planId}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }