<template>
  <div>
    <a @click="goToPlan" class="text-decoration-underline">
      {{ planid }}
    </a>
    <span
      v-if="trial"
      class="ml-1"
    >
      (Trial plan)
    </span>
  </div>
</template>

<script>
export default {
  name: 'PlanIdTemplate',
  props: {
    planid: {
      type: String,
      required: true,
    },
    trial: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToPlan() {
      this.$router.push({
        name: 'productionPlanDetails',
        params: { id: this.planid },
      });
    },
  },
};
</script>
