<template>
  <v-container
    fluid
    style="height:100%"
  >
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <portal to="app-header">
        <v-btn class="mb-1" icon @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span>Settings</span>
      </portal>
      <v-row>
        <v-col cols="12" md="3" xl="2">
          <v-list
            shaped
            class="pa-0 transparent"
          >
            <template v-for="(item, index) in items">
              <v-subheader
                :key="index"
                v-if="item.header"
                class="text-uppercase"
                v-text="$t(`${item.header}`)"
              ></v-subheader>
              <v-divider
                :key="index"
                v-else-if="item.divider"
              ></v-divider>
              <v-list-item
                link
                v-else
                :key="index"
                color="primary"
                :to="{ params: { id: item.to } }"
              >
                <v-list-item-title v-text="$t(`${item.title}`)"></v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
        <v-col cols="12" md="9" xl="7">
          <v-card
            flat
            class="transparent"
          >
            <v-card-title primary-title>
              {{ $t(`${selectedTitle}`) }}
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="text-none"
                @click="partDialog = true"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t('planning.updateMatrix') }}
              </v-btn>
              <portal-target name="settings-header"></portal-target>
            </v-card-title>
            <v-card-text>
              <v-fade-transition mode="out-in">
                <settings-window />
              </v-fade-transition>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <portal to="app-header" v-if="id">
        {{ $t(`${selectedTitle}`) }}
      </portal>
      <portal to="app-header" v-else>
        <v-btn class="mb-1" icon @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span>Settings</span>
        <portal-target name="settings-header" slim />
      </portal>
      <v-row no-gutters>
        <v-col cols="12" v-if="showList">
          <v-list class=" pa-0 transparent">
            <template v-for="(item, index) in items">
              <v-subheader
                :key="index"
                v-if="item.header"
                class="text-uppercase"
                v-text="$t(`${item.header}`)"
              ></v-subheader>
              <v-divider
                :key="index"
                v-else-if="item.divider"
              ></v-divider>
              <v-list-item
                link
                v-else
                :key="index"
                color="primary"
                :to="{ params: { id: item.to } }"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="$t(`${item.title}`)"></v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
        <v-col cols="12" v-else>
          <v-card
            flat
            class="transparent"
          >
            <v-card-text>
              <v-fade-transition mode="out-in">
                <settings-window />
              </v-fade-transition>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-dialog
      v-model="partDialog"
      scrollable
      persistent
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          {{ $t('planning.updateMatrix') }}
        </v-card-title>
        <v-card-text>
          <AddPart @closePartDialog="partDialog=false" @dateToSave="dateToSave"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import SettingsWindow from '../components/settings/SettingsWindow.vue';
import AddPart from '../components/actions/addPartForm.vue';

export default {
  name: 'ProductionPlanSettings',
  components: {
    SettingsWindow,
    AddPart,
  },
  data() {
    return {
      items: [
        {
          header: 'planning.settings.general',
        },
        {
          title: 'planning.settings.partMaster',
          to: 'parts',
        },
        {
          title: 'planning.settings.assetConfig',
          to: 'assets',
        },
        { divider: true },
        {
          header: 'planning.settings.importExport',
        },
        {
          title: 'planning.settings.importPlans',
          to: 'import',
        },
      ],
      routes: [],
      partDialog: false,
    };
  },
  created() {
    this.redirect();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    showList() {
      return this.id === undefined;
    },
    selectedTitle() {
      let title = '';
      const item = this.routes.find((r) => r.to === this.id);
      if (item) {
        ({ title } = item);
      }
      return title;
    },
  },
  watch: {
    id() {
      this.redirect();
    },
    // eslint-disable-next-line
    '$vuetify.breakpoint.name'() {
      this.redirect();
    },
  },
  methods: {
    ...mapActions('productionPlanning', ['addNewPartToMatrix']),
    goBack() {
      this.$router.push({ name: 'productionPlanning' });
    },
    redirect() {
      this.routes = this.items.filter((item) => item.to);
      const to = this.routes.map((item) => item.to);
      if (this.id === undefined && this.$vuetify.breakpoint.mdAndUp) {
        if (this.items && this.items.length) {
          this.$router.push({ params: { id: to[0] } });
        }
      } else if (this.id && this.$vuetify.breakpoint.mdAndUp) {
        const validId = to.includes(this.id);
        if (!validId) {
          const invalidPath = this.$route.fullPath;
          this.$router.push({ name: '404', params: { 0: invalidPath } });
        }
      }
    },
    async dateToSave(val) {
      // console.log('received Data from subcomp =>', val);
      if (val && Object.keys(val).length) {
        const allPartsLength = val.selectedParts.length;
        const allMachineLength = val.selectedMachines.length;
        const allMoldsLength = val.selectedMolds.length;
        const payloadObjects = [];
        for (let index1 = 0; index1 < allPartsLength; index1 += 1) {
          const element1 = val.selectedParts[index1];
          for (let index2 = 0; index2 < allMachineLength; index2 += 1) {
            const element2 = val.selectedMachines[index2];
            for (let index3 = 0; index3 < allMoldsLength; index3 += 1) {
              const element3 = val.selectedMolds[index3];
              const payloadObj = {
                netweight: parseFloat(val.formData.netweight),
                partname: element1,
                machinename: element2,
                cycletimetoleranceplus: parseInt(val.formData.cycletimeTolerancePlus, 10),
                cycletimetoleranceminus: parseInt(val.formData.cycletimeToleranceMinus, 10),
                delaytime: parseInt(val.formData.delayTime, 10),
                manpowercost: parseFloat(val.formData.manPowerCost),
                manufacturingcost: parseFloat(val.formData.manufacturingCost),
                cavity: parseInt(val.formData.cavity, 10),
                stdinjectiontime: parseInt(val.formData.standardInjectionTime, 10),
                moldname: element3,
                stdcycletime: parseInt(val.formData.standardCycletime, 10),
                assetid: val.formData.assetid,
              };
              payloadObjects.push(payloadObj);
            }
          }
        }
        // console.log('payload Object Part Matx=>', payloadObjects);
        await this.addNewPartToMatrix(payloadObjects);
      }
      // this.addNewPartToMatrix(val);
      this.partDialog = false;
    },
  },
};
</script>
