<template>
  <div>
    <validation-observer ref="form" #default="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(onSaveAndAddNew)">
      <v-container fluid>
        <v-row
          justify="center"
          :no-gutters="$vuetify.breakpoint.smAndDown"
        >
          <v-col cols="12" xl="9" class="text-justify">
            <v-card flat class="transparent">
              <v-card-text class="pt-0">
                <v-row>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="partname"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        outlined
                        :error-messages="errors"
                        item-value="partname"
                        item-text="partname"
                        label="Select Parts"
                        v-model="selectedParts"
                        :items="partList"
                        clearable
                        multiple
                        class="custom-autocomplete"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            class="fixed-height-list-item"
                            @click="selectAllParts"
                          >
                            <v-list-item-action>
                              <v-icon :color="selectedParts.length > 0 ? 'indigo darken-4' : ''">
                                {{ partIcon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ index }">
                          <span v-if="selectedParts.length === partList.length && index === 0">
                            All Parts Selected
                          </span>
                          <span v-else-if="index === 0">
                            {{ selectedParts.length }} Parts Selected
                          </span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="machinename"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        outlined
                        :error-messages="errors"
                        item-value="machinename"
                        item-text="machinename"
                        label="Select Machine"
                        v-model="selectedMachines"
                        :items="machineList"
                        clearable
                        multiple
                        class="custom-autocomplete"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            class="fixed-height-list-item"
                            @click="selectAllMachines"
                          >
                            <v-list-item-action>
                              <v-icon :color="selectedMachines.length > 0 ? 'indigo darken-4' : ''">
                                {{ machineIcon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ index }">
                          <span v-if="selectedMachines.length === machineList.length
                            && index === 0">
                            All Machines Selected
                          </span>
                          <span v-else-if="index === 0">
                            {{ selectedMachines.length }} Machines Selected
                          </span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="moldname"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        outlined
                        :error-messages="errors"
                        item-value="moldname"
                        item-text="moldname"
                        label="Select Molds"
                        v-model="selectedMolds"
                        :items="moldList"
                        clearable
                        multiple
                        class="custom-autocomplete"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            class="fixed-height-list-item"
                            @click="selectAllMolds"
                          >
                            <v-list-item-action>
                              <v-icon :color="selectedMolds.length > 0 ? 'indigo darken-4' : ''">
                                {{ moldIcon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ index }">
                          <span v-if="selectedMolds.length === moldList.length && index === 0">
                            All Molds Selected
                          </span>
                          <span v-else-if="index === 0">
                            {{ selectedMolds.length }} Molds Selected
                          </span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" style="marginTop: -3%">
                    <validation-provider
                      name="netweight"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planning.netWeight')"
                        type="number"
                        min="0"
                        :disabled="saving"
                        :error-messages="errors"
                        outlined
                        v-model="formData.netweight"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" style="marginTop: -3%">
                    <validation-provider
                      name="cycletimetoleranceplus"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planning.cycletimeTolerancePlus')"
                        type="number"
                        min="0"
                        :disabled="saving"
                        :error-messages="errors"
                        outlined
                        v-model="formData.cycletimeTolerancePlus"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" style="marginTop: -3%">
                    <validation-provider
                      name="cycletimetoleranceminus"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planning.cycletimeToleranceMinus')"
                        type="number"
                        min="0"
                        :disabled="saving"
                        :error-messages="errors"
                        outlined
                        v-model="formData.cycletimeToleranceMinus"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="delaytime"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planning.delayTime')"
                        type="number"
                        min="0"
                        :disabled="saving"
                        :error-messages="errors"
                        outlined
                        v-model="formData.delayTime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="manpowercost"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planning.manPowerCost')"
                        type="number"
                        :disabled="saving"
                        :error-messages="errors"
                        outlined
                        min="0"
                        v-model="formData.manPowerCost"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="manufacturingcost"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planning.manufacturingCost')"
                        type="number"
                        :disabled="saving"
                        :error-messages="errors"
                        outlined
                        min="0"
                        v-model="formData.manufacturingCost"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="cavity"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planning.cavity')"
                        type="number"
                        :disabled="saving"
                        :error-messages="errors"
                        outlined
                        min="0"
                        v-model="formData.cavity"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="standardinjectiontime"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planning.standardInjectionTime')"
                        type="number"
                        :disabled="saving"
                        :error-messages="errors"
                        outlined
                        min="0"
                        v-model="formData.standardInjectionTime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="standardcycletime"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('planning.standardCycleTime')"
                        type="number"
                        :disabled="saving"
                        :error-messages="errors"
                        outlined
                        min="0"
                        v-model="formData.standardCycletime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="primary"
                  class="text-none"
                  :disabled="saving"
                  @click="exit"
                >
                  {{ $t('planning.exit') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="primary"
                  class="text-none"
                  @click="onSaveAndExit"
                >
                  {{ $t('planning.updateMatrix') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </validation-observer>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'AddPart',
  data() {
    return {
      formData: {
        assetid: 2,
      },
      selectedParts: [],
      selectedMachines: [],
      selectedMolds: [],
      submitPartMatrix: false,
    };
  },
  computed: {
    ...mapState('user', ['licenses']),
    ...mapState('productionPlanning', ['parts', 'machines', 'molds']),
    saving() {
      return this.submitPartMatrix;
    },
    partList() {
      return this.parts;
    },
    selectedAllParts() {
      return this.selectedParts.length === this.partList.length;
    },
    selectedSomeParts() {
      return this.selectedParts.length > 0 && !this.partList;
    },
    partIcon() {
      if (this.selectedAllParts) return 'mdi-checkbox-marked';
      if (this.selectedSomeParts) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    machineList() {
      return this.machines;
    },
    selectedAllMachines() {
      return this.selectedMachines.length === this.machineList.length;
    },
    selectedSomeMachines() {
      return this.selectedMachines.length > 0 && !this.machineList;
    },
    machineIcon() {
      if (this.selectedAllMachines) return 'mdi-checkbox-marked';
      if (this.selectedSomeMachines) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    moldList() {
      return this.molds;
    },
    selectedAllMolds() {
      return this.selectedMolds.length === this.moldList.length;
    },
    selectedSomeMolds() {
      return this.selectedMolds.length > 0 && !this.moldList;
    },
    moldIcon() {
      if (this.selectedAllMolds) return 'mdi-checkbox-marked';
      if (this.selectedSomeMolds) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  methods: {
    exit() {
      this.clear();
      this.$emit('closePartDialog');
    },
    clear() {
      this.formData = {};
      this.selectedMolds = [];
      this.selectedParts = [];
      this.selectedMachines = [];
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    async onSaveAndExit() {
      this.formData.assetid = this.licenses[0].assetId;
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        const obj = {
          formData: this.formData,
          selectedParts: this.selectedParts,
          selectedMachines: this.selectedMachines,
          selectedMolds: this.selectedMolds,
        };
        this.$emit('dateToSave', obj);
        this.exit();
      }
    },
    selectAllParts() {
      this.$nextTick(() => {
        if (this.selectedAllParts) {
          this.selectedParts = [];
        } else {
          this.selectedParts = this.partList.map((part) => part.partname);
        }
      });
    },
    selectAllMachines() {
      this.$nextTick(() => {
        if (this.selectedAllMachines) {
          this.selectedMachines = [];
        } else {
          this.selectedMachines = this.machineList.map((part) => part.machinename);
        }
      });
    },
    selectAllMolds() {
      this.$nextTick(() => {
        if (this.selectedAllMolds) {
          this.selectedMolds = [];
        } else {
          this.selectedMolds = this.moldList.map((part) => part.moldname);
        }
      });
    },
  },
};
</script>
<style>
  .v-text-field--box .v-input__slot, .v-text-field--outline .v-input__slot{
    min-height:56px;
  }
  .custom-autocomplete .fixed-height-list-item {
  /* Set a fixed height for the selected list items */
  height: 32px;
  max-width: 100; /* Optional: to limit the width */
  white-space: nowrap;
  overflow: hidden;
}

.custom-autocomplete .v-input__control {
  /* Set a fixed height for the input area */
  min-height: 56px;
}

.custom-autocomplete .v-menu__content {
  /* Fix the position of the dropdown */
  position: absolute;
  z-index: 1000;
}

.custom-autocomplete .v-select__selections {
  /* Set a fixed height for the selection area */
  max-height: 56px; /* Adjust the height as needed */
  flex-wrap: nowrap; /* Prevent wrapping */
  overflow: hidden;
}
</style>
